import React from "react";
import Layout from "@/components/Layout/Layout";
import Seo from "@/components/Seo/Seo";
import { StaticImage } from "gatsby-plugin-image";

const AboutPage = () => (
  <Layout title="About Us">
    <Seo title="About" />
    <div className="max-w-[1350px] mx-auto text-white font-avantGarde">
      <StaticImage
        src="../assets/about1.webp"
        alt="logo"
        className="mt-8 md:mt-28 "
      />
      <div className="mt-16 mb-14 px-4 max-w-[835px] mx-auto">
        <div className="sm:w-3/4 text-base">
          <h2 className="text-[25px] font-medium mb-6">About</h2>
          <p className="mb-4">
          Voyeur Intime is the blockchain based fashion and digital art magazine of the Kama Sutra Lovers - the Parisian based creative duo for Sound + Vision.
          </p>
          <p className="mb-4">
          Every “page” is minted as an NFT on the Ethereum blockchain with the related media files uploaded to IPFS and Arweave for long term archival persistence.
          </p>
          <p className="mb-4">
          The vision is an archive, a time capsule, an immutable visual record of fashion and art with a focus on Paris - the capital of fashion, luxury, and art.
          </p>
          <p className="mb-4">
          10,000 unique NFTs will be minted over time with monthly drops. Off chain activities will include limited edition bookzines, one-off black and white platinum prints, museum grade colour Ilfochrome/FujiFlex Crystal Archive prints, and of course - surprise giveaways both onchain (airdrops) and offchain to Voyeur Intime collectors.
          </p>
          <p className="mb-4">
          Leveraging the founders deep networks in the fashion and art worlds to create unique content collaborations, Voyeur Intime is a pioneering platform fusing NFT & blockchain culture and technology with the unconventionality and modernism of the independent fashion magazine.
          </p>
          <p className="mb-4 font-bold">
          About the Kama Sutra Lovers (KSL)
          </p>
          <p className="mb-4">
          Immersed in the Parisian creative milieu of fashion editors, designers, stylists, artists, and models, KSL is a pioneering artistic duo releasing original music + visuals, while also collaborating with the fashion & luxury industries through creative direction, photography, film, and sound.
          </p>
          <p>
          </p>
          
        </div>
      </div>
      <div className="mb-14" id="contact">
        <StaticImage src="../assets/about2.webp" alt="logo" className="mb-14" />
        <div className="px-4 max-w-[835px] mx-auto">
          <div className="text-base sm:w-3/4">
            <h2 className="text-[25px] font-medium mb-6">Contact</h2>
            <p className="mb-4">
              Best way to reach us is via a DM on Instagram{" "}
              <a
                href="http://www.instagram.com/kamasutralovers"
                className="text-white hover:underline"
              >
                @KamaSutraLovers
              </a>{" "}
              or{" "}
              <a
                href="http://www.instagram.com/voyeurintime"
                className="text-white hover:underline"
              >
                @VoyeurIntime
              </a>
            </p>
            <p>
              Or alternatively via email:{" "}
              <a
                href="mailto:contact@KamaSutraLovers.com"
                className="text-white hover:underline"
              >
                contact@KamaSutraLovers.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default AboutPage;
